import { post, getToken, get } from '@/utils/request'

const getAiImages = async (category, page, pageSize, user_id) => {
  if (getToken() && localStorage.getItem('user_id')) {
    const res = await get(`v1/images/search_images_by_style_preset?style_preset=${category}&page=${page}&limit=${pageSize}&user_id=${user_id}`)
    return res
  }
  const res = await get(`v1/images/search_images_by_style_preset?style_preset=${category}&page=${page}&limit=${pageSize}`)
  return res
}

const getAllImages = async (page, pageSize, user_id, keyword) => {
  if (getToken() && localStorage.getItem('user_id')) {
    const res = await get(`v1/image/get_all_images?page=${page}&limit=${pageSize}&user_id=${user_id}&keyword=${keyword}`)
    return res
  }
  const res = await get(`v1/image/get_all_images?page=${page}&limit=${pageSize}&keyword=${keyword}`)
  return res
}

const getSearchImages = async (keyword, page, pageSize, user_id) => {
  if (getToken() && localStorage.getItem('user_id')) {
    const res = await get(`v1/images/search_images?keyword=${keyword}&page=${page}&limit=${pageSize}&user_id=${user_id}`)
    return res
  }
  const res = await get(`v1/images/search_images?keyword=${keyword}&page=${page}&limit=${pageSize}`)
  return res
}

const addSelectionImage = async (image_id) => {
  const res = await post(`v1/admin/add_selection_image?image_id=${image_id}`)
  return res
}

export const removeSelectionImage = async (image_id) => {
  const res = await post(`v1/admin/remove_selection_image?image_id=${image_id}`)
  return res
}

const addLikeImage = async (image_id) => {
  const res = await post(`v1/image/add_like/${image_id}`)
  return res
}

const disLikeImage = async (image_id) => {
  const res = await post(`v1/image/remove_like/${image_id}`)
  return res
}

const setImagePublic = async (image_id) => {
  const res = await post(`v1/images/set_image_public?image_id=${image_id}`)
  return res
}

const setImagePrivate = async (image_id) => {
  const res = await post(`v1/images/set_image_private?image_id=${image_id}`)
  return res
}

const deleteImage = async (image_id) => {
  const res = post(`v1/images/delete_image?image_id=${image_id}`)
  return res
}

const deactivateImage = async (image_id) => {
  const res = post(`v1/admin/deactivate_image?image_id=${image_id}`)
  return res
}

const fetchFastUpscale = async (task_id) => {
  const res = await get(`v1/tasks/fast_upscale/${task_id}`)
  return res
}

const fetchText2imgTaskStatus = async (task_id) => {
  const res = await get(`v1/image/task_status/${task_id}`)
  return res
}

const fetchImg2imgTaskStatus = async (task_id) => {
  const res = await get(`v1/tasks/image2image/${task_id}`)
  return res
}

const fetchSD3TaskStatus = async (task_id) => {
  const res = await get(`v1/tasks/sd_three/${task_id}`)
  return res
}

const fetchMjCreateTaskStatus = async (task_id) => {
  const res = await get(`v1/tasks/mj_create/${task_id}`)
  return res
}

const createUpscaleImage = async (image_base64, image_url, scale, face_enhance) => {
  const res = await post(`v1/generation/fast_upscale`, {
    image_base64,
    image_url,
    scale,
    face_enhance,
  })
  return res
}

const createMJImage = async (text_prompt, mode_speed) => {
  const res = await post(`v1/images/mj_create`, {
    text_prompt,
    mode_speed,
  })
  return res
}

const createTextToImage = async (prompt, aspect_ratio, num_outputs, seed, output_format, output_quality, disable_safety_checker) => {
  const res = await post('v1/image/create', {
    prompt,
    aspect_ratio,
    num_outputs,
    seed,
    output_format,
    output_quality,
    disable_safety_checker,
  })
  return res
}

const createImageToImage = async (
  engine_id,
  text_prompts,
  negative_prompts,
  samples,
  model_id,
  lora_model,
  lora_strength,
  sampler,
  seed,
  cfg_scale,
  steps,
  init_image,
  image_strength
) => {
  const res = await post('v1/generation/image-to-image', {
    text_prompts: [
      { text: text_prompts, weight: 1 },
      { text: negative_prompts, weight: -1 },
    ],
    samples,
    model_id,
    lora_model,
    lora_strength,
    seed,
    engine_id,
    sampler,
    cfg_scale,
    steps,
    image_strength,
    init_image,
  })
  return res
}

const createSD3Image = async (prompt, negative_prompt, aspect_ratio, width, height, steps, seeds) => {
  const res = await post(`v1/images/sd_three`, {
    prompt,
    negative_prompt,
    aspect_ratio,
    width,
    height,
    steps,
    seeds,
  })
  return res
}

const createMiaobiImage = async (text_content, font_name, output_image_ratio, prompt, texture_style, n, alpha_channel = false) => {
  const res = await post(`v1/images/jinshu_create`, {
    text_content,
    font_name,
    output_image_ratio,
    prompt,
    texture_style,
    n,
    alpha_channel,
  })
  return res
}

export {
  getAiImages,
  getAllImages,
  getSearchImages,
  addSelectionImage,
  addLikeImage,
  disLikeImage,
  setImagePrivate,
  setImagePublic,
  deleteImage,
  deactivateImage,
  fetchFastUpscale,
  fetchImg2imgTaskStatus,
  fetchText2imgTaskStatus,
  fetchMjCreateTaskStatus,
  fetchSD3TaskStatus,
  createImageToImage,
  createMJImage,
  createSD3Image,
  createMiaobiImage,
  createUpscaleImage,
  createTextToImage,
}
