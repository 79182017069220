import React, { useState, useEffect } from 'react'
import { Image, Navbar, NavbarBrand, Divider, NavbarContent } from '@nextui-org/react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { map, split } from 'lodash-es'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { getToken } from '@/utils/request'
import Button from '@/components/Button'
import { addLikeImage, disLikeImage } from '@/services/ai_images'
import IconFont from '@/components/IconFonts'
import { notify } from '@/utils/crypto'
import { useImage } from '@/swr/useImage'
import { useModel } from '@/plugin-model/useModel'

const ImageInfo = ({ image_id, images }) => {
  const router = useRouter()

  const { data, isValidating, mutate } = useImage(image_id)
  const { viewSet, view } = useModel('view')
  const [prev, setPrev] = useState(null)
  const [next, setNext] = useState(null)

  // 结合image_lora_list和image_lora_strength_list和loraList，一一对应

  // 在需要时手动触发数据重新验证
  useEffect(() => {
    mutate()
    // eslint-disable-next-line
  }, [image_id])

  useEffect(() => {
    // 防止页面滚动穿透
    const originalOverflow = window.getComputedStyle(document.body).overflow
    if (image_id) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      // 页面滚动恢复原状
      document.body.style.overflow = originalOverflow
    }
  }, [image_id])
  const [path] = split(router.asPath, '?')

  const imageIds = map(images, (v) => v.id)

  useEffect(() => {
    const index = imageIds?.indexOf(image_id)
    if (index !== -1) {
      setPrev(index > 0 ? imageIds[index - 1] : null)
      setNext(index < imageIds?.length - 1 ? imageIds[index + 1] : null)
    }
  }, [imageIds, image_id])

  const [isExpandedPositive, setIsExpandedPositive] = useState(false)
  const [isExpandedNegative, setIsExpandedNegative] = useState(false)

  const toggleTextPositive = () => setIsExpandedPositive(!isExpandedPositive)
  const toggleTextNegative = () => setIsExpandedNegative(!isExpandedNegative)

  const handleKeyDownPositive = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      toggleTextPositive()
    }
  }

  const handleKeyDownNegative = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      toggleTextNegative()
    }
  }

  const [isLiked, setIsLiked] = useState(data?.is_liked_by_user)

  const handleLike = () => {
    if (getToken()) {
      if (isLiked) {
        disLikeImage(data?.id).then((res) => {
          if (res.code === 200) {
            setIsLiked(false)
          } else {
            notify(res.messsage, 'info')
          }
        })
      } else {
        addLikeImage(data.id).then((res) => {
          if (res.code === 200) {
            setIsLiked(true)
          } else {
            notify(res.messsage, 'info')
          }
        })
      }
    } else {
      viewSet('loginDialogOpen', true)
    }
  }

  return (
    <div className={`${image_id ? 'fixed inset-0 z-50 block bg-background' : 'hidden'}`}>
      <div className="relative h-full w-full">
        <Navbar isBordered maxWidth="full">
          <NavbarContent justify="start">
            <NavbarBrand>
              <NavbarBrand onClick={() => router.push('/')} className="flex items-center gap-3">
                <Image src="/logo.png" width={40} height={40} alt="" radius="full" />
                <p className="cursor-pointer font-bold text-inherit">AI</p>
              </NavbarBrand>
            </NavbarBrand>
          </NavbarContent>
          <NavbarContent justify="end">
            <Button
              isIconOnly
              radius="full"
              onClick={() => {
                if (router.asPath.includes(`/image`)) {
                  router.push('/gallery')
                } else {
                  router.push(path, undefined, { shallow: true })
                }
              }}
            >
              <IconFont icon="icon-close" className="text-xs font-bold" />
            </Button>
          </NavbarContent>
        </Navbar>
        {prev && (
          <div className="absolute left-2 top-1/2 z-50">
            <Button isIconOnly radius="full" onClick={() => router.push(`${path}?image_id=${prev}`, undefined, { shallow: true })}>
              <IconFont icon="icon-arrow-left" />
            </Button>
          </div>
        )}
        {next && (
          <div className="absolute right-2 top-1/2 z-50">
            <Button isIconOnly radius="full" onClick={() => router.push(`${path}?image_id=${next}`, undefined, { shallow: true })}>
              <IconFont icon="icon-arrow-right" />
            </Button>
          </div>
        )}
        <div className="mx-auto flex h-content w-full flex-col overflow-auto lg:flex-row">
          <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-6">
            <Image alt="" className="m-auto h-full max-h-[700px] w-full max-w-[600px] object-contain" src={data?.image_url} isBlurred isLoading={isValidating} />
          </div>
          <div className="h-full w-full max-w-full rounded-none border-l border-solid border-divider p-6 lg:w-[500px] lg:overflow-auto">
            <div className="flex w-full items-start justify-between">
              <div className="flex w-full flex-col gap-0 break-all">
                <div className="flex items-center justify-between text-sm text-foreground/60">
                  <div>提示词</div>
                  <CopyToClipboard text={data?.text_prompts} onCopy={() => notify('复制成功')}>
                    <Button isIconOnly size="sm" variant="light">
                      <IconFont icon="icon-copy" className="text-xs" />
                    </Button>
                  </CopyToClipboard>
                </div>
                <div role="button" tabIndex={0} onClick={toggleTextPositive} onKeyDown={handleKeyDownPositive} className={`mt-1 ${!isExpandedPositive ? 'line-clamp-8' : ''}`}>
                  {data?.text_prompts}
                </div>
                {data?.negative_prompts && (
                  <>
                    <div className="mt-6 flex w-full items-center justify-between text-sm text-foreground/60">
                      <div>反向提示词</div>
                      <CopyToClipboard text={data?.negative_prompts} onCopy={() => notify('复制成功')}>
                        <Button isIconOnly size="sm" variant="light">
                          <IconFont icon="icon-copy" className="text-xs" />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    <div role="button" tabIndex={0} onClick={toggleTextNegative} onKeyDown={handleKeyDownNegative} className={`mt-1 ${!isExpandedNegative ? 'line-clamp-8' : ''}`}>
                      {data?.negative_prompts}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Divider className="my-3" />
            <div className="mb-6 grid grid-cols-1 gap-4">
              {data?.aspect_ratio && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">图片比例</div>
                  <p className="mt-1 text-small text-foreground">{data?.aspect_ratio}</p>
                </div>
              )}
              {data?.seed !== null && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">随机种子</div>
                  <p className="mt-1 text-small text-foreground">{data?.seed}</p>
                </div>
              )}
              {data?.created_at && (
                <div className="flex items-center justify-between">
                  <di className="text-sm text-foreground/60">创建时间</di>
                  <p className="mt-1 text-small text-foreground">{dayjs(data?.created_at).format('YYYY-MM-DD HH:mm')}</p>
                </div>
              )}
            </div>
            <Divider className="my-3" />
            <div className="mt-6 flex w-full items-center justify-between gap-6">
              <Button
                color="primary"
                className="px-12"
                startContent={<IconFont icon="icon-sd" />}
                onClick={() => {
                  viewSet('promptConfig', {
                    ...view.promptConfig,
                    prompt: data?.text_prompts,
                    aspect_ratio: data?.aspect_ratio,
                    seed: data?.seed,
                  })
                  router.push('/')
                }}
              >
                生成同款
              </Button>
              <div className="flex items-center gap-4">
                <Button
                  variant="bordered"
                  startContent={<IconFont icon={isLiked ? 'icon-like' : 'icon-dislike'} className={`${isLiked ? 'text-rose-500' : ''}`} />}
                  isIconOnly
                  onClick={() => {
                    handleLike()
                  }}
                />
                <Button
                  variant="bordered"
                  startContent={<IconFont icon="icon-download" />}
                  isIconOnly
                  onClick={() => {
                    router.push(`${data?.image_url}?download/${data?.image_url.split('/').pop()}`)
                  }}
                />
                <CopyToClipboard text={data?.image_url} onCopy={() => notify('链接已复制')}>
                  <Button variant="bordered" startContent={<IconFont icon="icon-link" />} isIconOnly />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageInfo
